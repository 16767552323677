<template>
  <div v-if="user" class="validation-page">
    <b-button
      v-if="!isImpersonating"
      variant="primary"
      class="d-block logout-button"
      @click.prevent="logout"
    >
      <span>LogOut</span>
    </b-button>
    <div v-if="isImpersonating && !user.subscribed" class="text-center mb-4">
      <button
        class="btn btn-outline-primary btn-sm"
        :disabled="loadingStopImpersonate"
        @click="stopImpersonate"
      >
        <b-spinner v-if="loadingStopImpersonate" small />
        <i class="uil uil-sign-in-alt"></i> Main account
      </button>
    </div>
    <div class="row">
      <div class="col-md-6 offset-md-3">
        <div class="card">
          <div class="card-body">
            <h3 class="h3 text-center mb-4">
              Email not verified
            </h3>
            <div class="my-4 text-center">
              Please check your email to find your validation link.
            </div>
            <div class="text-center">
              <b-button variant="info" :disabled="disableResendButton" @click="resendEmail">
                <template v-if="!showCountdown || remainingTime === 0">Resend</template>
                <template v-else>
                  Resend in {{ remainingTime }} seconds
                </template>
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loadingStopImpersonate: false,
      canResendTime: null,
      showCountdown: false,
      remainingTime: 0,
    };
  },

  computed: {
    disableResendButton() {
      return this.canResendTime && this.remainingTime > 0;
    },

    isImpersonating() {
      return this.$store.getters['auth/isImpersonating'];
    },

    user() {
      return this.$store.getters['auth/user'];
    },
  },

  mounted() {
    if(this.user.email_verified_at !== null){
      this.$router.push({ name: 'agency.dashboard' })
    }
    this.restTimer();
  },

  methods: {
    logout() {
      this.$store
          .dispatch('auth/logout', this.user)
          .then(() => {
            this.$router.push({ name: 'login' })
          })
          .catch(() => {})
    },

    stopImpersonate() {
      this.loadingStopImpersonate = true

      this.$store
          .dispatch('auth/stopImpersonate')
          .then(() => {
            this.$store
                .dispatch('auth/getProfile')
                .then((user) => {
                  this.$router.push('/admin')
                  this.loadingStopImpersonate = false
                })
                .catch(() => {
                  this.loadingStopImpersonate = false
                })
          })
          .catch(() => {
            this.loadingStopImpersonate = false
          })
    },

    resendEmail(){
      this.restTimer();
      this.$store
          .dispatch('auth/resendValidationEmail',this.user)
          .then(() => {
          })
          .catch(() => {})
    },

    restTimer() {
      this.canResendTime = Date.now() + 90000;

      this.startCountdown();
    },

    startCountdown() {
      this.showCountdown = true;

      // Calculate remaining time every second
      const interval = setInterval(() => {
        this.remainingTime = Math.ceil((this.canResendTime - Date.now()) / 1000);

        // Stop countdown when remaining time is 0
        if (this.remainingTime <= 0) {
          clearInterval(interval);
          this.showCountdown = false;
        }
      }, 1000);
    },
  },
};
</script>

<style lang="scss">
.validation-page {
  .logout-button {
    max-width: 200px;
    position: absolute;
    top: 50px;
    right: 50px;
  }
}
</style>
